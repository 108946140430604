<br>
<input type='checkbox' id='mmeennuu'>
<label class='menu' for='mmeennuu'>

    <div class='barry'>
        <span class='bar'></span>
        <span class='bar'></span>
        <span class='bar'></span>
        <span class='bar'></span>
    </div>

    <ul>
        <li><a id='programming' href='#programming' (click)="setFilter('programming')">Programming</a></li>
        <li><a id='design' href='#design' (click)="setFilter('design')" >Design</a></li>
        <li><a id='narrative' href='#narrative' (click)="setFilter('narrative')">Narrative</a></li>
        <li><a id='all' href='#all' (click)="setFilter('all')">All</a></li>
    </ul>
</label>
<section id="cd-timeline" class="cd-container">
    <div *ngFor="let item of data; let i = index" class="cd-timeline-block">
        <div class="cd-timeline-img cd-picture" [class.cd-img-1]="i == 0 || i == 3 || i == 6 ||  i == 9 ||  i == 12"
            [class.cd-img-2]="i == 1 || i == 4 || i == 7 ||  i == 10 ||  i == 13"
            [class.cd-img-3]="i == 2 || i == 5 || i == 8 ||  i == 11 ||  i == 14">
        </div>
        <div class="cd-timeline-content cd-1" [class.cd-1]="i == 0 || i == 3 || i == 6 ||  i == 9 ||  i == 12"
            [class.cd-2]="i == 1 || i == 4 || i == 7 ||  i == 10 ||  i == 13" [class.cd-3]="i == 2 || i == 5 || i == 8 ||  i == 11 ||  i == 14">
            <h2> {{item.name}} <img *ngIf="item.platform_icon" height="25px" width="25px" [src]="item.platform_icon" />
            </h2>
            <p *ngIf="item.screenshots.length == 0" [innerHTML]="item.short_description">
                {{item.short_description}}
            </p>
            <img class="timeline-img" *ngIf="item.screenshots.length > 0" [src]="item?.screenshots[0]?.img" alt="">
            <span class="cd-date">{{item.date}}</span>
            <a  *ngIf="item.link != ''" [href]="item.link.url" target="_blank" class="button">{{item.link.name}}</a>
            <a  *ngIf="item.long_description" (click)="open(item, i)" class="button">More</a>
        </div>
    </div>
</section>