import { BrowserModule} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TimelineComponent } from './timeline/timeline.component';
import { OverviewComponent } from './overview/overview.component';
import { ProjectDetailsModal } from './project-details-modal/project-details-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';  

@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
    TimelineComponent,
    ProjectDetailsModal
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbModule,
    CommonModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
