<header>
  <div class="container">
    <h1 class="logo">
      Julian<span>Spinelli</span>/portfolio
    </h1>
    <section class="social">
      <a class="button" href="https://github.com/JSpinelli" target="_blank">Github</a>
      <a class="button" href="https://twitter.com/JuliSpinelli_" target="_blank">Twitter</a>
      <a class="button" href="https://julian-spinelli.itch.io/" target="_blank">Itch.io</a>
      <!-- <a class="btn" href="https://medium.com/@julianspi95" target="_blank">Medium</a> -->
      <a class="button" href="../assets/resume/Resume.pdf" target="_blank"> <b> My Resume </b></a>
    </section>
  </div>
</header>

<app-timeline></app-timeline>