<div class="modal-body">
  <div class="container">
    <div class="row" *ngIf="data.screenshots.length > 0">
      <div class="col">
        <ngb-carousel class="carousel" >
          <ng-template *ngFor="let image of data.screenshots; let i = index" ngbSlide class="img-wrapper">
            <div class="img-wrapper">
              <img [src]="image.img" [alt]="image.desc" class="img-wrapper">
            </div>
            <div class="carousel-caption">
              <h3>{{image.desc}}</h3>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
    <div class="row gx-5">
      <div class="col">
        <br>
        <h3>{{data.name}}</h3>
        <p class="desc" [innerHTML]="data.long_description">{{data.long_description}}</p>
      </div>
    </div>
    <div class="row gx-5" *ngIf="data.credits">
      <div class="col">
        <br>
        <h4>Work</h4>
        <p class="desc">{{data.credits}}</p>
      </div>
    </div>
    <div class="row gx-5" *ngIf="data.awards">
      <div class="col">
        <br>
        <h4>Awards</h4>
        <p class="desc" [innerHTML]="data.awards">{{data.awards}}</p>
      </div>
    </div>
    <div class="row gx-5" *ngIf="data.interviews">
      <div class="col">
        <br>
        <h4>Interviews</h4>
        <p class="desc" [innerHTML]="data.interviews">{{data.interviews}}</p>
      </div>
    </div>
    <div class="row gx-5" *ngIf="data.credits">
      <div class="col">
        <a [href]="data.link.url" target="_blank" class="button">{{data.link.name}}</a>
      </div>
    </div>
  </div>
</div>