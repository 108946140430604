import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ProjectDetailsModal } from '../project-details-modal/project-details-modal.component';

import projects_data from "../../assets/projects-data.json";
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
})
export class TimelineComponent {
  title = 'portfolio';
  data: any;


  constructor(private modalService: NgbModal) {
    this.data = projects_data.projects;
    this.data = this.data.sort((a, b) => a.priority - b.priority)
  }


  open(data: any, index: any) {
    const modalRef = this.modalService.open(ProjectDetailsModal, {
      animation: true,
      centered: true,
      size: "xl"
    })
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.index = index;
  }

  setFilter(filter: string) {
    this.data = projects_data.projects;
    
    if (filter == "all"){
      this.data = this.data.sort((a, b) => b.date_order - a.date_order);
      return;
    }
      
    this.data = this.data.sort((a, b) => a.priority - b.priority);
    this.data = this.data.filter((a) => a.skills.includes(filter))
  }
}
