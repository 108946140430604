import { Component, Input} from '@angular/core';
import {NgbActiveModal, NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';



@Component({
    selector: 'app-sample',
    templateUrl: './project-details-modal.component.html',
    providers: [NgbCarouselConfig],
})
export class ProjectDetailsModal{
    @Input() data;
    @Input() index;

    constructor(public activeModal: NgbActiveModal, config: NgbCarouselConfig) { 
        config.interval = 10000;
        config.wrap = true;
        config.keyboard = true;
        config.pauseOnHover = true;
        config.animation = true;
    }
}