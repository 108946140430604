<div class="resume">
  <div class="func">
    <div class="about">
      <h3><i class="fa fa-briefcase"></i>About Me</h3>
      <p style="margin-left: 3%;"> A programmer whose passion is solving problems. Always interested in a challenge that tests my programming
        skills and my creativity. <br><br>

        I found my interest in game development by DMing my own campaing and since then I have been trying to get into
        the industry. Currently I’m an MFA in NYU’s Game Design program. <br><br>

        I have experience dealing with clients representing companies and I’m always on the look out for ways of
        optimizing my and my team’s workflow.</p>
    </div>
    <div class="progs">
      <h3><i class="fa fa-graduation-cap"></i>Programming Languages</h3>
      <ul>
        <li>Javascript</li>
        <li>C#</li>
        <li>Java</li>
        <li>C++</li>
        <li>Dart</li>
        <li>SQL</li>
      </ul>
    </div>
    <div class="frameworks">
      <h3><i class="fas fa-code"></i>Frameworks</h3>
        <ul>
          <li>Unity</li>
          <li>NodeJS</li>
          <li>Angular</li>
          <li>Flutter</li>
        </ul>
      <ul>

      </ul>
    </div>
    <div class="trivia">
      <h3><i class="fas fa-bezier-curve"></i>Trivia</h3>
      <ul>
        <li>I’m a below average surfer</li>
        <li>I will always accept an offer to play
          Divinity Original Sin 2 or Stardew Valley</li>
        <li>I’m a big fan of Critical Role</li>
        <li>I'm still waiting for a Cryptic Spren to show up</li>
      </ul>
    </div>
  </div>
</div>